

.logo {
  height: 4rem;
}
.Carouselimg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.icons {
  width: 80px; /* sets the width of the image to half its original size */
  height: 80px; /* sets the height of the image to half its original size */
}

.greenmsg {
  color: green;
  padding-top: 20px;
}
/* this is navbar dropdown menu */

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

.hamburger {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.hidden {
  display: none;
}
.close-button{
  position: absolute;
  top: 0px;
  right: 0px;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
